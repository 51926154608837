import { lazy, ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AllRoutes from 'shared/config/routes';
import PrivateRoute from 'pages/_ui/PrivateRoute';
import { allUserRolesEnum } from 'shared/config/roles';

const Runs = lazy(() => import('pages/runs'));
const RedirectFromT1kToRunId = lazy(() => import('pages/run/[t1k]/viewer'));
const Viewer = lazy(() => import('pages/runs/[runId]/viewer'));
const NotFound = lazy(() => import('pages/404'));
const AdminPage = lazy(() => import('pages/admin'));

function Pages(): ReactElement {
  return (
    <Routes>
      <Route
        path={AllRoutes.RUNS}
        element={
          <PrivateRoute
            userRoles={[
              allUserRolesEnum.CUSTOMER,
              allUserRolesEnum.READ_ONLY,
              allUserRolesEnum.ANALYST,
              allUserRolesEnum.AUDITOR,
              allUserRolesEnum.DEVELOPER,
            ]}
            redirectToOnError={AllRoutes.ADMIN}
          >
            <Runs />
          </PrivateRoute>
        }
      />
      <Route
        path={AllRoutes.ADMIN}
        element={
          <PrivateRoute
            userRoles={[allUserRolesEnum.SUPERVISOR, allUserRolesEnum.ADMIN]}
            redirectToOnError={AllRoutes.RUNS}
          >
            <AdminPage />
          </PrivateRoute>
        }
      />
      <Route path="/" element={<Navigate to={AllRoutes.RUNS} replace />} />
      <Route path={AllRoutes.CHART} element={<Viewer />} />
      <Route path={AllRoutes.RUN_T1K} element={<RedirectFromT1kToRunId />} />
      <Route path={AllRoutes.RUN_T1K_VIEWER} element={<RedirectFromT1kToRunId />} />
      <Route
        path={AllRoutes.RUNS_RUNID}
        element={
          <Navigate
            to={AllRoutes.RUNS_RUNID_VIEWER.replace(
              '/runs/:runId/viewer',
              `${window.location.pathname}/viewer${window.location.search}`
            )}
            replace
          />
        }
      />
      <Route path={AllRoutes.RUNS_RUNID_VIEWER} element={<Viewer />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Pages;
