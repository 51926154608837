/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { axiosInstance } from 'shared/api/http-common';
import { HitsMetadata } from 'shared/types/hitsMetadata';
import {
  RecognitionInterface,
  generatedFromRecognitionTypes,
} from 'pages/runs/[runId]/viewer/constants';

const INITIAL_CURRENT_SUSPECT_IN_MODAL_STATE = {
  isCurrentSuspectInModalLoading: false,
  isCurrentSuspectMetadataLoading: false,
  currentSuspectInModal: null,
  isUnsavedChangesOnSuspectModal: false,
  isUnsavedChangesOnSuspectModalOpened: false,
  currentSuspectMetadata: null,
};

const parseRecognitionPATCHToBackendBased = (recognition: RecognitionInterface): any => ({
  rail: recognition.rail,
  rail_id: recognition.rail_id,
  pulse_count: recognition.pulse_count,
  pulse_count_start: recognition.pulse_count_start,
  pulse_count_end: recognition.pulse_count_end,
  depth_start: recognition.depth_start,
  depth_end: recognition.depth_end,
  suspect: recognition.suspect,
  text_uic_code: recognition.text_uic_code,
  priority: recognition.priority,
  milepost: recognition.milepost,
  comment: recognition.comment,
  historic: recognition.historic,
  historic_type: recognition.historic_type,
  verifier_comment: recognition.verifier_comment,
  lat: recognition.lat,
  long: recognition.long,
  elmer_text_uic_code: recognition.elmer_text_uic_code,
  generated_from: recognition.generated_from,
});

const parseRecognitionPOSTToBackendBased = ({
  rail,
  rail_id,
  pulse_count,
  pulse_count_start,
  pulse_count_end,
  depth_start,
  depth_end,
  suspect,
  text_uic_code,
  priority,
  milepost,
  comment,
  historic,
  historic_type,
  source,
  lat,
  long,
  elmer_text_uic_code,
  generated_from,
}: RecognitionInterface): any => ({
  rail,
  rail_id,
  pulse_count,
  pulse_count_start,
  pulse_count_end,
  depth_start,
  depth_end,
  suspect,
  text_uic_code,
  priority,
  milepost,
  comment,
  historic,
  historic_type,
  source,
  lat,
  long,
  elmer_text_uic_code,
  generated_from,
});

export const currentSuspectInModalSlice = createSlice({
  name: 'currentSuspectInModal',
  initialState: INITIAL_CURRENT_SUSPECT_IN_MODAL_STATE,
  reducers: {
    setIsCurrentSuspectInModalLoading: (state, action) => {
      state.isCurrentSuspectInModalLoading = action.payload;
    },
    setCurrentSuspectInModal: (state, action) => {
      state.currentSuspectInModal = action.payload;
    },
    setIsUnsavedChangesOnSuspectModal: (state, action) => {
      state.isUnsavedChangesOnSuspectModal = action.payload;
    },
    setIsUnsavedChangesOnSuspectModalOpened: (state, action) => {
      state.isUnsavedChangesOnSuspectModalOpened = action.payload;
    },
    setCurrentSuspectMetadata: (state, action) => {
      state.currentSuspectMetadata = action.payload;
    },
    setCurrentSuspectMetadataLoading: (state, action) => {
      state.isCurrentSuspectMetadataLoading = action.payload;
    },
    resetCurrentSuspectInModalToInitialState: () => INITIAL_CURRENT_SUSPECT_IN_MODAL_STATE,
  },
});

export const {
  setIsCurrentSuspectInModalLoading,
  setCurrentSuspectInModal,
  setIsUnsavedChangesOnSuspectModal,
  setIsUnsavedChangesOnSuspectModalOpened,
  setCurrentSuspectMetadata,
  setCurrentSuspectMetadataLoading,
  resetCurrentSuspectInModalToInitialState,
} = currentSuspectInModalSlice.actions;

export const fetchPatchRecognition =
  ({
    runId,
    recognition,
    onSuccess,
  }: {
    recognition: RecognitionInterface;
    runId: string | number;
    onSuccess: (arg0: { updatedRecognition: RecognitionInterface }) => void;
  }) =>
  async (dispatch) => {
    dispatch(setIsCurrentSuspectInModalLoading(true));
    try {
      const response = await axiosInstance.patch(
        `runs/${runId}/recognitions/${recognition.recognition_id}`,
        parseRecognitionPATCHToBackendBased(recognition)
      );
      if (response.data) {
        // TODO: add implementation of updatedRecognition which we get from the response from BE
        // const updatedRecognition = Array.isArray(response.data) ? response.data[0] : response.data;

        if (onSuccess) onSuccess({ updatedRecognition: recognition });
      } else {
        toast.error('Recognition update failed');
      }
    } catch (err) {
      toast.error('Recognition update failed');
    }
    dispatch(setIsCurrentSuspectInModalLoading(false));
  };

export const fetchPostRecognition =
  ({
    runId,
    recognition,
    onSuccess,
  }: {
    recognition: RecognitionInterface;
    runId: string | number;
    onSuccess: (arg0: { createdRecognition: RecognitionInterface }) => void;
  }) =>
  async (dispatch) => {
    dispatch(setIsCurrentSuspectInModalLoading(true));
    try {
      const response = await axiosInstance.post(
        `runs/${runId}/recognitions`,
        parseRecognitionPOSTToBackendBased(recognition)
      );
      const createdRecognition = Array.isArray(response.data)
        ? { ...response.data[0], generated_from: generatedFromRecognitionTypes.ULTRASOUND }
        : { ...response.data, generated_from: generatedFromRecognitionTypes.ULTRASOUND };

      if (response.data) {
        if (onSuccess) onSuccess({ createdRecognition });
      } else {
        toast.error('Recognition creation failed');
      }
    } catch (err) {
      toast.error('Recognition creation failed');
    }
    dispatch(setIsCurrentSuspectInModalLoading(false));
  };

export const fetchCurrentSuspectMetadata =
  ({ runId, recognitionId }: { runId: string | number; recognitionId: string }) =>
  async (dispatch) => {
    dispatch(setCurrentSuspectMetadataLoading(true));
    try {
      const response = await axiosInstance.get(
        `runs/${runId}/recognitions/${recognitionId}/metadata`
      );

      if (response.data) {
        dispatch(setCurrentSuspectMetadata(response.data));
      } else {
        toast.error(`No metadata for this recognition (${recognitionId}).`);
      }
    } catch (err) {
      toast.error('Error while getting recognition metadata');
    }
    dispatch(setCurrentSuspectMetadataLoading(false));
  };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectIsCurrentSuspectInModalLoading = (state): boolean =>
  state.currentSuspectInModal.isCurrentSuspectInModalLoading;
export const selectCurrentSuspectInModal = (state): any =>
  state.currentSuspectInModal.currentSuspectInModal;
export const selectIsUnsavedChangesOnSuspectModal = (state): boolean =>
  state.currentSuspectInModal.isUnsavedChangesOnSuspectModal;
export const selectIsUnsavedChangesOnSuspectModalOpened = (state): boolean =>
  state.currentSuspectInModal.isUnsavedChangesOnSuspectModalOpened;
export const selectCurrentSuspectHitsMetadata = (state): HitsMetadata =>
  state.currentSuspectInModal.currentSuspectMetadata?.hits_metadata;
export const selectCurrentSuspectHitsMetadataLoading = (state): boolean =>
  state.currentSuspectInModal.isCurrentSuspectMetadataLoading;

export default currentSuspectInModalSlice.reducer;
