import dexieDb from 'app/db/dexieDb';

export const writeLoadedSegmentsAroundRecognition = (recognitionId: string): void => {
  dexieDb
    .transaction('rw', dexieDb.loadedSegmentsAroundRecognitionId, async () => {
      const existingSegments = await dexieDb.loadedSegmentsAroundRecognitionId
        .where('recognition_id')
        .equals(recognitionId)
        .toArray();

      if (!existingSegments.length) {
        dexieDb.loadedSegmentsAroundRecognitionId.add({
          recognition_id: recognitionId,
        });
      }
    })
    .catch((err) => {
      console.log('error in writing segments to IndexedDb, err: ', err);
    });
};
