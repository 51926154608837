import { ReactElement } from 'react';

function TrackLocationsUnsavedChangesWarningIconSVG(): ReactElement {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7814_47866)">
        <path
          d="M9.90017 30H30.1303C30.6018 29.9967 31.0653 29.8789 31.4805 29.6569C31.8956 29.4349 32.2497 29.1155 32.5119 28.7264C32.7741 28.3373 32.9365 27.8904 32.9848 27.4247C33.0331 26.959 32.9659 26.4887 32.7891 26.0547L22.5295 8.48021C22.2796 8.03173 21.9132 7.65791 21.4685 7.3976C21.0238 7.13729 20.517 7 20.0008 7C19.4845 7 18.9777 7.13729 18.533 7.3976C18.0883 7.65791 17.7219 8.03173 17.472 8.48021L7.21245 26.0547C7.03897 26.4788 6.97017 26.9379 7.01181 27.3937C7.05346 27.8495 7.20432 28.2888 7.45182 28.675C7.69932 29.0611 8.03627 29.3829 8.43453 29.6134C8.8328 29.8439 9.28083 29.9764 9.74122 30"
          stroke="#D8D8D8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 17L20 19.6622M20 24.9867L20 25"
          stroke="#D8D8D8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7814_47866">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default TrackLocationsUnsavedChangesWarningIconSVG;
