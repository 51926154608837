/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import isEqual from 'lodash.isequal';
import { nanoid } from 'nanoid';

import { axiosInstance } from 'shared/api/http-common';
import { EMPTY_OBJECT } from 'shared/utils';
import dexieDb, {
  TscanRunsDexieDb,
  TscanRunsEdRecognitionsDexieDb,
  TscanRunsRecognitionsDexieDb,
} from 'app/db/dexieDb';
import { generatedFromRecognitionTypes } from 'pages/runs/[runId]/viewer/constants';

const DATE_NOW = Math.round(Date.now() / 1000);

interface TscanRun {
  age: number;
  current_end_pulse_count: number;
  current_start_pulse_count: number;
  datasource_id: string;
  datasource_name: string;
  matched_recognitions: number;
  original_run_id: string;
  original_run_name: string;
  same_direction: boolean;
  running_mode: string;
}

const INITIAL_VIEWER_TSCAN_STATE = {
  currentRunDateTime: DATE_NOW,
  isAllTscanMatchedDataSourcesDataLoading: false,
  isAllTscanRecognitionsDataLoading: false,
  allTscanMatchedDataSourcesDataError: null,
  isTscanRecognitionsLoadedAndSavedToDexieDb: false,
  isTscanRecognitionsLoadedAndSavedWithCustomerCodesData: false,
  // for correct change handler using useLiveQuery from dexie-db (it doesn't provide from box)
  tscanRecognitionsDexieDbUpdater: 0,
  allTscanRecognitionsDataError: null,
  // !!currentSelectedRunOnTscan equals as TSCAN_MODE === true
  currentSelectedRunOnTscan: null,
  isTscanBscanWithInductionCurveDataLoading: false,
  tscanBscanWithInductionCurveData: {
    topRailData: {
      visionImages: [],
      ultrasoundHits: [],
      inductionCurve: [],
    },
    bottomRailData: {
      visionImages: [],
      ultrasoundHits: [],
      inductionCurve: [],
    },
  },
};

export const tscanSlice = createSlice({
  name: 'tscan',
  initialState: INITIAL_VIEWER_TSCAN_STATE,
  reducers: {
    setIsAllTscanMatchedDataSourcesDataLoading: (state, action) => {
      state.isAllTscanMatchedDataSourcesDataLoading = action.payload;
    },
    setIsAllTscanRecognitionsDataLoading: (state, action) => {
      state.isAllTscanRecognitionsDataLoading = action.payload;
    },
    setAllTscanMatchedDataSourcesDataError: (state, action) => {
      state.allTscanMatchedDataSourcesDataError = action.payload;
    },
    setAllTscanRecognitionsDataError: (state, action) => {
      state.allTscanRecognitionsDataError = action.payload;
    },
    setIsTscanRecognitionsLoadedAndSavedToDexieDb: (state, action) => {
      state.isTscanRecognitionsLoadedAndSavedToDexieDb = action.payload;
    },
    setIsTscanRecognitionsLoadedAndSavedWithCustomerCodesData: (state, action) => {
      state.isTscanRecognitionsLoadedAndSavedWithCustomerCodesData = action.payload;
    },
    increaseTscanRecognitionsDexieDbUpdater: (state) => {
      state.tscanRecognitionsDexieDbUpdater += 1;
    },
    setCurrentSelectedRunOnTscan: (state, action) => {
      state.currentSelectedRunOnTscan = action.payload;
    },
    setCurrentRunDateTime: (state, action) => {
      state.currentRunDateTime = action.payload;
    },
    setIsTscanBscanWithInductionCurveDataLoading: (state, action) => {
      state.isTscanBscanWithInductionCurveDataLoading = action.payload;
    },
    setTscanBscanWithInductionCurveData: (state, action) => {
      state.tscanBscanWithInductionCurveData = action.payload;
    },
    resetViewerTscanToInitialState: () => INITIAL_VIEWER_TSCAN_STATE,
  },
});

export const {
  setIsAllTscanMatchedDataSourcesDataLoading,
  setIsAllTscanRecognitionsDataLoading,
  setAllTscanMatchedDataSourcesDataError,
  setAllTscanRecognitionsDataError,
  setIsTscanRecognitionsLoadedAndSavedToDexieDb,
  setIsTscanRecognitionsLoadedAndSavedWithCustomerCodesData,
  increaseTscanRecognitionsDexieDbUpdater,
  setCurrentSelectedRunOnTscan,
  setCurrentRunDateTime,
  setIsTscanBscanWithInductionCurveDataLoading,
  setTscanBscanWithInductionCurveData,
  resetViewerTscanToInitialState,
} = tscanSlice.actions;

// recursion for tscan recognitions of each matched data sources
const fetchTscanRecognitionsDataRecursion = async ({
  runId,
  tscanRunId,
  offset = 0,
  limit = 4000,
  tscanRecognitionsData = [],
  dispatch,
}: // eslint-disable-next-line consistent-return
any): Promise<any> => {
  try {
    const tscanRecognitionsResponse = await axiosInstance.get(
      `/runs/${runId}/t-scans/matched-datasources/${tscanRunId}/recognitions`,
      {
        params: { offset, limit },
      }
    );

    tscanRecognitionsData.push(...tscanRecognitionsResponse.data);

    if (tscanRecognitionsResponse.data.length < limit) {
      return tscanRecognitionsData;
    }

    return await fetchTscanRecognitionsDataRecursion({
      runId,
      tscanRunId,
      offset: offset + limit,
      tscanRecognitionsData,
    });
  } catch (err) {
    dispatch(setAllTscanRecognitionsDataError(err));
  }
};

export const fetchGetTscanRecognitionsByRunId =
  ({ runId, tscanRunsForDexieDb }: { runId: string; tscanRunsForDexieDb: TscanRunsDexieDb[] }) =>
  async (dispatch) => {
    dispatch(setIsAllTscanRecognitionsDataLoading(true));

    try {
      const tscanRecognitions: TscanRunsRecognitionsDexieDb[] = [];
      const tscanEdRecognitions: TscanRunsEdRecognitionsDexieDb[] = [];

      for (let i = 0; i < tscanRunsForDexieDb.length; i += 1) {
        const tscanRun = tscanRunsForDexieDb[i];
        // eslint-disable-next-line no-await-in-loop
        const allTscanRecognitionsByTscanRunId = await fetchTscanRecognitionsDataRecursion({
          runId,
          tscanRunId: tscanRun.original_run_id,
          dispatch,
        });

        allTscanRecognitionsByTscanRunId.forEach((tscanRecognition: any) => {
          if (
            tscanRecognition.generated_from === generatedFromRecognitionTypes.ENHANCED_DETECTION
          ) {
            tscanEdRecognitions.push({
              current_pulse_count_start:
                tscanRecognition.current_pulse_count -
                (tscanRecognition.pulse_count - tscanRecognition.pulse_count_start),
              current_pulse_count_end:
                tscanRecognition.current_pulse_count +
                (tscanRecognition.pulse_count_end - tscanRecognition.pulse_count),
              recognition_id: tscanRecognition.recognition_id,
              current_rail_id: tscanRecognition.current_rail_id,
              recognition_obj: tscanRecognition,
              original_run_id: tscanRun.original_run_id,
              original_run_age: tscanRun.age,
              original_run_name: tscanRun.original_run_name,
              same_direction: tscanRun.same_direction ? 1 : 0,
            });
          } else {
            tscanRecognitions.push({
              current_pulse_count: tscanRecognition.current_pulse_count,
              recognition_id: tscanRecognition.recognition_id,
              current_rail_id: tscanRecognition.current_rail_id,
              recognition_obj: tscanRecognition,
              original_run_id: tscanRun.original_run_id,
              original_run_age: tscanRun.age,
              original_run_name: tscanRun.original_run_name,
              same_direction: tscanRun.same_direction ? 1 : 0,
            });
          }
        });
      }

      if (tscanRecognitions.length) {
        dispatch(setAllTscanRecognitionsDataError(null));
        try {
          const isSuccess = await dexieDb.tscanRunsRecognitions.bulkAdd(tscanRecognitions);
          if (isSuccess) {
            dispatch(increaseTscanRecognitionsDexieDbUpdater());
            dispatch(setIsTscanRecognitionsLoadedAndSavedToDexieDb(true));
          } else {
            toast.error('error in writing tscanRunsRecognitions to indexedDb');
          }
        } catch (err) {
          console.log('error in writing tscanRunsRecognitions to indexedDb');
        }
      } else {
        dispatch(setAllTscanRecognitionsDataError(tscanRecognitions));
        toast.error('Failed to load tscanRunsRecognitions data');
      }

      if (tscanEdRecognitions.length) {
        try {
          const isSuccess = await dexieDb.tscanRunsEdRecognitions.bulkAdd(tscanEdRecognitions);
          console.log('tscan edRecognitions sucessfully loaded: ', isSuccess);
        } catch (err) {
          console.log('error in writing tscan ED Recognitions to indexedDb');
        }
      }
    } catch (error) {
      dispatch(setAllTscanRecognitionsDataError(error));
      toast.error('Failed to load tscanRunsRecognitions data');
    }

    dispatch(setIsAllTscanRecognitionsDataLoading(false));
  };

// recursion for matched data sources
const fetchTscanMatchedDataSourcesRecursion = ({
  runId,
  offset = 0,
  limit = 20,
  matchedDataSourcesList = [],
  dispatch,
}: any): Promise<any> =>
  axiosInstance
    .get(`/runs/${runId}/t-scans/matched-datasources`, {
      params: { offset, limit },
    })
    .then((runMapResponse) => {
      matchedDataSourcesList.push(...runMapResponse.data);

      if (runMapResponse.data.length < limit) {
        return matchedDataSourcesList;
      }

      return fetchTscanMatchedDataSourcesRecursion({
        runId,
        offset: offset + limit,
        matchedDataSourcesList,
      });
    })
    .catch((error) => {
      dispatch(setAllTscanMatchedDataSourcesDataError(error));
    });

export const fetchGetTscanMatchedDataSourcesByRunId =
  ({ runId }) =>
  async (dispatch) => {
    dispatch(setIsAllTscanMatchedDataSourcesDataLoading(true));

    try {
      const tscanMatchedDataSourcesFinalData: TscanRun[] =
        await fetchTscanMatchedDataSourcesRecursion({
          runId,
          dispatch,
        });

      if (tscanMatchedDataSourcesFinalData?.length) {
        const tscanRunsForDexieDb: TscanRunsDexieDb[] = tscanMatchedDataSourcesFinalData.map(
          ({
            age,
            current_end_pulse_count,
            current_start_pulse_count,
            matched_recognitions,
            original_run_id,
            original_run_name,
            same_direction,
            running_mode,
          }) => ({
            age,
            current_end_pulse_count,
            current_start_pulse_count,
            matched_recognitions,
            original_run_id,
            original_run_name,
            same_direction: same_direction ? 1 : 0,
            running_mode,
          })
        );

        try {
          await dexieDb.tscanRuns.bulkPut(tscanRunsForDexieDb);
        } catch (err) {
          console.log('error in writing tscanRuns to IndexedDb, err: ', err);
        }

        dispatch(
          fetchGetTscanRecognitionsByRunId({
            runId,
            tscanRunsForDexieDb,
          })
        );
      } else {
        dispatch(setAllTscanMatchedDataSourcesDataError(tscanMatchedDataSourcesFinalData));
        toast.error('Failed to load tscan runs data');
      }
    } catch (error) {
      dispatch(setAllTscanMatchedDataSourcesDataError(error));
      toast.error('Failed to load tscan runs data');
    }
    dispatch(setIsAllTscanMatchedDataSourcesDataLoading(false));
  };

export const fetchGetTscanBscanWithInductionCurveData =
  ({ runId, queryParams, pulseCountOffset, isReverseRailData, sameDirectionPulseCount = null }) =>
  async (dispatch) => {
    dispatch(setIsTscanBscanWithInductionCurveDataLoading(true));

    if (queryParams.segment_start < 0) {
      queryParams.segment_start = 0;
    }

    try {
      const prevRunSegmentsResponse = await axiosInstance.get(`runs/${runId}/segments`, {
        params: queryParams,
      });
      if (prevRunSegmentsResponse.data) {
        const topRailDataComputed: any = {
          visionImages: [],
          ultrasoundHits: [],
          inductionCurve: [],
        };
        const bottomRailDataComputed: any = {
          visionImages: [],
          ultrasoundHits: [],
          inductionCurve: [],
        };
        prevRunSegmentsResponse.data?.forEach((runSegment: any) => {
          if (isEqual(runSegment, EMPTY_OBJECT)) {
            return;
          }
          // TODO: check if it is possible just change currentAxiosPulseCounts
          // instead of recalculating pulsecount values
          topRailDataComputed.inductionCurve.push(
            ...runSegment.rail_1.induction_curve.map((inductionCurve) => ({
              ...inductionCurve,
              pulse_count:
                sameDirectionPulseCount === null
                  ? inductionCurve.pulse_count + pulseCountOffset
                  : inductionCurve.pulse_count +
                    pulseCountOffset +
                    (sameDirectionPulseCount - inductionCurve.pulse_count) * 2,
            }))
          );
          topRailDataComputed.visionImages.push(
            ...runSegment.rail_1.vision_images.map((visionImage) => ({
              ...visionImage,
              pulse_count_start:
                sameDirectionPulseCount === null
                  ? visionImage.pulse_count_start + pulseCountOffset
                  : visionImage.pulse_count_start +
                    pulseCountOffset +
                    (sameDirectionPulseCount - visionImage.pulse_count_start) * 2,
              pulse_count_end:
                sameDirectionPulseCount === null
                  ? visionImage.pulse_count_end + pulseCountOffset
                  : visionImage.pulse_count_end +
                    pulseCountOffset +
                    (sameDirectionPulseCount - visionImage.pulse_count_end) * 2,
              id: visionImage.id || nanoid(),
            }))
          );
          topRailDataComputed.ultrasoundHits.push(
            ...runSegment.rail_1.ultrasound_hits.map((ultrasoundHit) => ({
              ...ultrasoundHit,
              pulse_count:
                sameDirectionPulseCount === null
                  ? ultrasoundHit.pulse_count + pulseCountOffset
                  : ultrasoundHit.pulse_count +
                    pulseCountOffset +
                    (sameDirectionPulseCount - ultrasoundHit.pulse_count) * 2,
            }))
          );

          bottomRailDataComputed.inductionCurve.push(
            ...runSegment.rail_0.induction_curve.map((inductionCurve) => ({
              ...inductionCurve,
              pulse_count:
                sameDirectionPulseCount === null
                  ? inductionCurve.pulse_count + pulseCountOffset
                  : inductionCurve.pulse_count +
                    pulseCountOffset +
                    (sameDirectionPulseCount - inductionCurve.pulse_count) * 2,
            }))
          );
          bottomRailDataComputed.visionImages.push(
            ...runSegment.rail_0.vision_images.map((visionImage) => ({
              ...visionImage,
              pulse_count_start:
                sameDirectionPulseCount === null
                  ? visionImage.pulse_count_start + pulseCountOffset
                  : visionImage.pulse_count_start +
                    pulseCountOffset +
                    (sameDirectionPulseCount - visionImage.pulse_count_start) * 2,
              pulse_count_end:
                sameDirectionPulseCount === null
                  ? visionImage.pulse_count_end + pulseCountOffset
                  : visionImage.pulse_count_end +
                    pulseCountOffset +
                    (sameDirectionPulseCount - visionImage.pulse_count_end) * 2,
              id: visionImage.id || nanoid(),
            }))
          );
          bottomRailDataComputed.ultrasoundHits.push(
            ...runSegment.rail_0.ultrasound_hits.map((ultrasoundHit) => ({
              ...ultrasoundHit,
              pulse_count:
                sameDirectionPulseCount === null
                  ? ultrasoundHit.pulse_count + pulseCountOffset
                  : ultrasoundHit.pulse_count +
                    pulseCountOffset +
                    (sameDirectionPulseCount - ultrasoundHit.pulse_count) * 2,
            }))
          );
        });

        dispatch(
          setTscanBscanWithInductionCurveData({
            topRailData: isReverseRailData ? bottomRailDataComputed : topRailDataComputed,
            bottomRailData: isReverseRailData ? topRailDataComputed : bottomRailDataComputed,
          })
        );
      } else {
        toast.error('Failed to load tscan data for previous Run');
        dispatch(setCurrentSelectedRunOnTscan(null));
      }
    } catch (err) {
      dispatch(setCurrentSelectedRunOnTscan(null));
      toast.error('Failed to load tscan data for previous Run');
    }
    dispatch(setIsTscanBscanWithInductionCurveDataLoading(false));
  };

export const selectIsAllTscanMatchedDataSourcesDataLoading = (state): boolean =>
  state.tscan.isAllTscanMatchedDataSourcesDataLoading;
export const selectIsAllTscanRecognitionsDataLoading = (state): boolean =>
  state.tscan.isAllTscanRecognitionsDataLoading;
export const selectAllTscanMatchedDataSourcesDataError = (state): any =>
  state.tscan.allTscanMatchedDataSourcesDataError;
export const selectAllTscanRecognitionsDataError = (state): any =>
  state.tscan.allTscanRecognitionsDataError;
export const selectIsTscanRecognitionsLoadedAndSavedToDexieDb = (state): boolean =>
  state.tscan.isTscanRecognitionsLoadedAndSavedToDexieDb;
export const selectIsTscanRecognitionsLoadedAndSavedWithCustomerCodesData = (state): boolean =>
  state.tscan.isTscanRecognitionsLoadedAndSavedWithCustomerCodesData;
export const selectTscanRecognitionsDexieDbUpdater = (state): boolean =>
  state.tscan.tscanRecognitionsDexieDbUpdater;
export const selectCurrentSelectedRunOnTscan = (state): any =>
  state.tscan.currentSelectedRunOnTscan;
export const selectCurrentRunDateTime = (state): any => state.tscan.currentRunDateTime;
export const selectIsTscanBscanWithInductionCurveDataLoading = (state): boolean =>
  state.tscan.isTscanBscanWithInductionCurveDataLoading;
export const selectTscanBscanWithInductionCurveData = (state): any =>
  state.tscan.tscanBscanWithInductionCurveData;

export default tscanSlice.reducer;
