/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import moment from 'moment';

import { axiosInstance } from 'shared/api/http-common';
import {
  RealRunStatuses,
  RUNS_LIMIT_FOR_PAGINATION,
  dateRangeValues,
  userProcessingValues,
} from 'shared/utils/constants/RunsInfoConstants';
import { checkIsWspSearchByDatasourceName } from 'shared/utils';
import AllRoutes from 'shared/config/routes';

export interface QueryParams {
  limit: number;
  offset: number;
  date_range_from: number;
  date_range_to: number;
  text_search: string;
  sandv: boolean;
  nonstop: boolean;
  analyst_assigned: null | string;
  auditor_assigned: null | string;
  status: string;
  sorting: string;
  date_range_select: dateRangeValues;
  user_processing: userProcessingValues;
}

type runsState = {
  runsData: Array<any>;
  isRunsDataLoading: boolean;
  runsDataError: string | null;
  runsQueryParams: QueryParams;
  // TODO: add real type below
  runsCountStatuses: any;
};

export const initialDataForRunsQueryParams: QueryParams = {
  limit: RUNS_LIMIT_FOR_PAGINATION,
  offset: 0,
  date_range_from: moment().subtract(48, 'hours').unix(),
  date_range_to: moment().unix(),
  sandv: false,
  nonstop: true,
  text_search: '',
  analyst_assigned: null,
  auditor_assigned: null,
  status: RealRunStatuses.ALL,
  sorting: '',
  date_range_select: dateRangeValues.HOURS_48,
  user_processing: userProcessingValues.ALL,
};

const runsQueryParamsNotToSendToBE = ['date_range_select'];

const INITIAL_RUNS_STATE: runsState = {
  runsData: [],
  isRunsDataLoading: false,
  runsDataError: null,
  runsQueryParams: initialDataForRunsQueryParams,
  runsCountStatuses: {},
};

export const runsSlice = createSlice({
  name: 'runs',
  initialState: INITIAL_RUNS_STATE,
  reducers: {
    setRunsDataFromResponse: (state, action) => {
      const { offset, runsDataFromResponse } = action.payload;
      if (offset) {
        state.runsData = [...state.runsData, ...runsDataFromResponse];
      } else {
        state.runsData = runsDataFromResponse;
      }
    },
    setRunsData: (state, action) => {
      state.runsData = action.payload;
    },
    setIsRunsDataLoading: (state, action) => {
      state.isRunsDataLoading = action.payload;
    },
    setRunsQueryParams: (state, action) => {
      state.runsQueryParams = action.payload;
    },
    setRunsCountStatuses: (state, action) => {
      state.runsCountStatuses = action.payload;
    },
  },
});

export const {
  setRunsDataFromResponse,
  setRunsData,
  setIsRunsDataLoading,
  setRunsQueryParams,
  setRunsCountStatuses,
} = runsSlice.actions;

export const getRunsDataAsync =
  ({
    t,
    allQueryParamsFromURL = {},
    setSearchParams,
    initialRunsFetch = false,
    offset = 0,
    limit = 20,
  }) =>
  async (dispatch, getState) => {
    const { runsQueryParams, isRunsDataLoading } = getState().runs;
    // TODO: recheck the line below
    if (isRunsDataLoading) return;

    const existedAdditionalQueryParams = {};
    Object.keys(initialDataForRunsQueryParams).forEach((runsQueryParamField) => {
      if (allQueryParamsFromURL[runsQueryParamField]) {
        // TODO: check lines below and redo
        let fieldValue: any = allQueryParamsFromURL[runsQueryParamField];
        if (allQueryParamsFromURL[runsQueryParamField] === 'true') {
          fieldValue = true;
        } else if (allQueryParamsFromURL[runsQueryParamField] === 'false') {
          fieldValue = false;
        } else if (allQueryParamsFromURL[runsQueryParamField] === 'null') {
          fieldValue = null;
        } else if (runsQueryParamField === 'offset' || runsQueryParamField === 'limit') {
          fieldValue = +fieldValue;
        }
        existedAdditionalQueryParams[runsQueryParamField] = fieldValue;
      }
    });

    dispatch(setIsRunsDataLoading(true));
    const resultRunsQueryParams = initialRunsFetch
      ? { ...runsQueryParams, ...existedAdditionalQueryParams, offset, limit }
      : { ...runsQueryParams, offset, limit };

    const dateRangeSelectValuesToReset = [
      dateRangeValues.HOURS_48,
      dateRangeValues.LAST_7_DAYS,
      dateRangeValues.LAST_30_DAYS,
      dateRangeValues.ALL_TIME,
    ];
    if (dateRangeSelectValuesToReset.includes(resultRunsQueryParams.date_range_select)) {
      let new_date_range_to: number | '' = moment().unix();
      let new_date_range_from: number | '' = moment().unix();

      if (resultRunsQueryParams.date_range_select === dateRangeValues.HOURS_48) {
        new_date_range_from = moment().subtract(48, 'hours').unix();
      } else if (resultRunsQueryParams.date_range_select === dateRangeValues.LAST_7_DAYS) {
        new_date_range_from = moment().subtract(7, 'days').unix();
      } else if (resultRunsQueryParams.date_range_select === dateRangeValues.LAST_30_DAYS) {
        new_date_range_from = moment().subtract(30, 'days').unix();
      } else if (resultRunsQueryParams.date_range_select === dateRangeValues.ALL_TIME) {
        new_date_range_from = '';
        new_date_range_to = '';
      }

      if (new_date_range_from && new_date_range_to) {
        resultRunsQueryParams.date_range_to = new_date_range_to;
        resultRunsQueryParams.date_range_from = new_date_range_from;
      } else {
        delete resultRunsQueryParams.date_range_to;
        delete resultRunsQueryParams.date_range_from;
      }
    }

    // we are setting this query parameters to our query-string in browser and to redux
    setSearchParams({ ...resultRunsQueryParams });
    dispatch(setRunsQueryParams({ ...resultRunsQueryParams }));

    // then remove query parameters that not needed for BackEnd and send request to BE
    runsQueryParamsNotToSendToBE.forEach((queryParamNotForBE) => {
      if (Object.prototype.hasOwnProperty.call(resultRunsQueryParams, queryParamNotForBE)) {
        delete resultRunsQueryParams[queryParamNotForBE];
      }
    });

    // WP4-1237 set datasource_name or customer_name using pattern
    if (checkIsWspSearchByDatasourceName(resultRunsQueryParams.text_search)) {
      resultRunsQueryParams.datasource_name = resultRunsQueryParams.text_search;
    } else {
      resultRunsQueryParams.customer_name = resultRunsQueryParams.text_search;
    }
    delete resultRunsQueryParams.text_search;

    if (resultRunsQueryParams.user_processing === userProcessingValues.NOT_ANALYZED) {
      resultRunsQueryParams.analyst_progress = 'LESS_THAN:100';
    }
    if (resultRunsQueryParams.user_processing === userProcessingValues.NOT_AUDITED) {
      resultRunsQueryParams.analyst_progress = 'EQUALS:100';
      resultRunsQueryParams.auditor_progress = 'LESS_THAN:100';
    }
    delete resultRunsQueryParams.user_processing;

    console.log('request to the BE for runs with runsQueryParams: ', resultRunsQueryParams);

    try {
      const response = await axiosInstance.get('runs', {
        params: resultRunsQueryParams,
      });
      if (response.data) {
        const runsDataFromResponse = response.data.runs;
        dispatch(setRunsDataFromResponse({ runsDataFromResponse, offset }));
        dispatch(setRunsCountStatuses(response.data.count_statuses));
      } else {
        toast.error(t('api_errors.runs.get_fail'));
      }
    } catch (err) {
      toast.error(t('api_errors.runs.get_error'));
    }

    localStorage.setItem('queryParamsOffset', '');
    dispatch(setIsRunsDataLoading(false));
  };

export const getRunFromT1kDataAsync =
  ({ t, t1k, allParamsFromUrl, navigate = (_url): void | null => null }) =>
  async () => {
    try {
      const response = await axiosInstance.get('runs', {
        params: { datasource_name: t1k },
      });
      if (response.data) {
        const runsDataFromResponse = response.data.runs;

        if (Array.isArray(runsDataFromResponse) && runsDataFromResponse.length) {
          navigate(
            AllRoutes.RUNS_RUNID_VIEWER.replace(
              ':runId/viewer',
              `${runsDataFromResponse[0]?.id}/viewer?${allParamsFromUrl}`
            )
          );
        } else {
          navigate(AllRoutes.RUNS);
          toast.error(t('No t1k was found'));
        }
      } else {
        navigate(AllRoutes.RUNS);
        toast.error(t('Fail to get the run by requested t1k name'));
      }
    } catch (err) {
      navigate(AllRoutes.RUNS);
      toast.error(t('Error to get the run by requested t1k name'));
    }
  };

export const selectRunsData = (state): Array<any> => state.runs.runsData;
export const selectRunsQueryParams = (state): QueryParams => state.runs.runsQueryParams;
export const selectIsRunsDataLoading = (state): boolean => state.runs.isRunsDataLoading;
export const selectRunsCountStatuses = (state): any => state.runs.runsCountStatuses;
export const selectQueryParamsBeforeRunOpen = (state): any => state.runs.queryParamsBeforeRunOpen;

export default runsSlice.reducer;
